
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { mapGetters } from "vuex";
import CourseSelect from "./../../../../components/courseSelect/index.vue";
import {Caxios} from "@/utils/axios";
const _this = new Vue();

@Component({
  name: "CourseDetail",
  components: { CourseSelect },
  computed: {
    ...mapGetters('common', ['lang'])
  }
})
export default class CourseDetail extends Vue {
  @Prop() id!: string
  lang!: any
  private classInfo: any = {
    name: '',
    list: []
  }
  private schoolInfo: any = {
    name: '',
    list: []
  }
  private teacherInfo: any = {
    name: '',
    list: []
  }
  private tableResult: any = []
  private reqObj: any = {}
  private courseDetail: any = {}
  private selectClassItem: any = {}
  private selectSchoolItem: any = {}
  private selectTeacherItem: any = {}
  private selectTableItem: any = []
  private activityLevel: any = null

  private async delQueryOption (type: number) {
    console.log('number',type,this.selectTableItem)
    switch(type) {
      case 0:
        this.$set(this,'selectClassItem',{})
        break
      case 1:
        this.$set(this,'selectSchoolItem',{})
        break
      case 2:
        this.$set(this,'selectTeacherItem',{})
        break
      case 3:
        this.$set(this,'selectTableItem',[])
        break
      case 4:
        this.$set(this,'selectClassItem',{})
        this.$set(this,'selectSchoolItem',{})
        this.$set(this,'selectTeacherItem',{})
        this.$set(this,'selectTableItem',[])
        this.selectSchoolItem.schoolAreaNameName=''
        break
    }
    this.getClassOptionsInfo()
  }
  //选班-课程老师的介绍
  private getCourseDetail () {
    Caxios.post({ url: '/api/official/course/courseDetail' ,data:{id:this.id}}).then((res: any) => {
      if(res.code === '10000'){
        this.courseDetail = res.data;
        (this as any).$eventBus.$emit('courseDetail', this.courseDetail)
      }
    })
  }
  // 获取筛选
  private async getClassOptionsInfo () {
    const res: any = await Caxios.post({ url: '/api/official/course/classOptionsInfo' ,data:{courseId:this.id}})
    if(res.code === '10000'){
      if ((!res.data.classLevelSelectList && !res.data.schoolAreaSelectList && !res.data.teacherSelectList) || (res.data.classLevelSelectList.length === 0 && res.data.schoolAreaSelectList.length === 0 && res.data.teacherSelectList.length === 0)) {
        this.$router.go(-1)
      }
      // 班级
      this.$set(this.classInfo, 'list', res.data.classLevelSelectList)
      this.reqObj.classLevelSelectList = res.data.classLevelSelectList
      // 校区
      this.$set(this.schoolInfo, 'list', res.data.schoolAreaSelectList)
      this.reqObj.schoolAreaSelectList = res.data.schoolAreaSelectList
      // 老师
      this.$set(this.teacherInfo, 'list', res.data.teacherSelectList)
      this.reqObj.teacherSelectList = res.data.teacherSelectList
      this.activityLevel = res.data.recommendClassLevel
      await this.generateClassTable()
    }
  }
  // 获取表格数据
  private async generateClassTable () {
    const res: any = await Caxios.post({ url: '/api/official/course/generateClassTable' ,data:{
      ...this.reqObj,
        courseId: this.id,
        classLevelCode: this.selectClassItem.classLevelCode ? this.selectClassItem.classLevelCode : '',
        teacherId: this.selectTeacherItem.teacherId ? this.selectTeacherItem.teacherId : '',
        schoolAreaId: this.selectSchoolItem.schoolAreaId ? this.selectSchoolItem.schoolAreaId : '',
        classIdList: this.selectTableItem.map((cell: { classId: any }) => cell.classId),
      }})
    if (res.code === '10000') {
      this.tableResult = CourseDetail.setTableData(res)
      // 班级
      this.$set(this.classInfo, 'list', res.data.classLevelSelectList)
      // 校区
      this.$set(this.schoolInfo, 'list', res.data.schoolAreaSelectList)
      // 老师
      this.$set(this.teacherInfo, 'list', res.data.teacherSelectList)
      const tableList = res.data.classTableResponseVO.bodyList
      Object.keys(tableList).forEach(i => {
        Object.keys(tableList[i].bodyDataList).forEach(b => {
          if (tableList[i].bodyDataList[b].checked) {
            this.selectTableItem = tableList[i].bodyDataList[b]
                    .classInfoList
          }
        })
      })
      // 获取选中结果
      this.getSelectRes()
    }
  }
  // 转换表格数据
  private static setTableData (res: any) {
    const tableBodyData = res.data.classTableResponseVO.bodyList
    const head = res.data.classTableResponseVO.headList
    const body = tableBodyData
    const tableList = [{
      headList: head,
      bodyList: body
    }]
    console.log('tableList', tableList)
    return tableList
  }
  // 选择班级
  private async selectClass(flag: any, value: { value: any; index: string | number }, name: any) {
    console.log('flag', flag, value)
    if (flag) return false
    Object.keys(this.classInfo.list).forEach(i => {
      this.$set(this.classInfo.list[i], 'on', false)
    })
    if (!value.value) {
      this.$set(this.classInfo.list[value.index], 'on', true)
      this.selectClassItem = this.classInfo.list[value.index]
      console.log('this.selectClassItem',this.selectClassItem)
    } else {
      this.selectClassItem = {}
    }
    await this.generateClassTable()
  }
  // 选择校区
  private async selectSchool(flag: any, value: { value: any; index: string | number }, name: any) {
    if (flag) return false
    Object.keys(this.schoolInfo.list).forEach(i => {
      this.$set(this.schoolInfo.list[i], 'on', false)
    })
    if (!value.value) {
      this.$set(this.schoolInfo.list[value.index], 'on', true)
      this.selectSchoolItem = this.schoolInfo.list[value.index]
      console.log('this.selectClassItem', this.selectSchoolItem)
    } else {
      this.selectSchoolItem = {}
    }
    await this.generateClassTable()
  }
  // 选择老师
  private async selectTeacher(flag: any, value: { value: any; index: string | number }, name: any) {
    if (flag) return false
    Object.keys(this.teacherInfo.list).forEach(i => {
      this.$set(this.teacherInfo.list[i], 'on', false)
    })
    if (!value.value) {
      this.$set(this.teacherInfo.list[value.index], 'on', true)
      this.selectTeacherItem = this.teacherInfo.list[value.index]
    } else {
      this.selectTeacherItem = {}
    }
    await this.generateClassTable()
  }
  // 选择表格
  private async selectTable(flag: string, value: { value: any; cIndex: string | number; index: string | number; subIndex: string | number }) {
    if (flag === 'empty') return false
    console.log('flag, value', flag, value)
    if (!value.value) {
      this.selectTableItem = this.tableResult[value.cIndex].bodyList[value.index].bodyDataList[value.subIndex]
              .classInfoList
      console.log('selectTableItem',this.selectTableItem)
    } else {
      this.selectTableItem = []
    }
    await this.generateClassTable();
  }
  // 获取选中结果
  private async getSelectRes () {
    console.log('条件')
    if (
            Object.keys(this.selectClassItem).length > 0
            && Object.keys(this.selectSchoolItem).length > 0
            && Object.keys(this.selectTeacherItem).length > 0
            && this.selectTableItem.length > 0
    ) {
      console.log('this.selectTableItem', this.selectTableItem[0]);
      (this as any).$eventBus.$emit('courseSelect', this.selectTableItem[0]);
    }else{
      (this as any).$eventBus.$emit('courseDetail', this.courseDetail)
    }
  }
  @Watch('id', { immediate: true, deep: true })
  idWatch(newVal: any): void {
    this.getClassOptionsInfo();
    this.getCourseDetail();
  }
}
