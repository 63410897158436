var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"mine-curriculum"},[_c('div',{staticClass:"cur-course"},[(_vm.id == 0)?_c('div',{staticClass:"test-title"},[_vm._v(_vm._s(_vm.$t('course.t26')))]):_vm._e(),(_vm.id != 0)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectInfo.courseId),expression:"!selectInfo.courseId"}],staticClass:"tac no-course-wrapper"},[_c('img',{attrs:{"src":require("@/assets/imgs/no_course.png"),"alt":""}}),_c('p',{staticClass:"nc-text"},[_vm._v(_vm._s(_vm.$t('course.t7')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectInfo.courseId),expression:"selectInfo.courseId"}]},[_c('p',{staticClass:"mb8px cc-title"},[_vm._v(_vm._s(_vm.selectInfo.courseName))]),(_vm.selectInfo.className)?_c('p',{staticClass:"mb8px cc-body"},[_vm._v(_vm._s(_vm.selectInfo.className || '-'))]):_vm._e(),(_vm.selectObj.id &&
      _vm.selectObj.termtype &&
      _vm.selectObj.teachername &&
      _vm.selectObj.week &&
      _vm.selectObj.during &&
      _vm.selectObj.classlevel &&
      _vm.selectObj.areas
      )?_c('p',{staticClass:"mb8px cc-sub-title"},[_vm._v(" "+_vm._s(_vm.selectObj.classname || '-')+" ")]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectInfo.classFrequency),expression:"selectInfo.classFrequency"}],staticClass:"mb8px cc-body"},[_vm._v(_vm._s(_vm.$t('course.t27'))+"："+_vm._s(_vm.selectInfo.classFrequency))]),_c('p',{staticClass:"mb8px cc-body"},[_vm._v(_vm._s(_vm.$t('course.t28'))+"："+_vm._s(_vm.selectInfo.lessonCount))]),_c('p',{staticClass:"mb8px cc-body"},[_vm._v(" "+_vm._s(_vm.$t('course.t29'))+"："+_vm._s(_vm.selectInfo.courseTime)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectInfo.schoolAreaName),expression:"selectInfo.schoolAreaName"}],staticClass:"cc-body"},[_vm._v(" "+_vm._s(_vm.$t('course.t30'))+"："+_vm._s(_vm.selectInfo.schoolAreaName)+" ")])])]):_vm._e(),_c('div',{staticClass:"thin-width"}),_c('div',{staticClass:"flex ai-c jc-s mb8px sign-up-wrapper"},[_c('p',[_vm._v(" "+_vm._s((_vm.personInfo?.moneySymbol || 'S$') + ' ' + ((_vm.id == 0 ? _vm.selectTestInfo?.totalPrice : _vm.selectInfo?.totalPrice) || 0))+" ")]),(_vm.pathType === 'updata')?_c('p',{staticClass:"tac cur su-btn",class:{
        disabled: (_vm.id == 0 && !_vm.submitOn) || (_vm.id != 0 && !_vm.submitOn)
      },staticStyle:{"position":"relative"},on:{"click":_vm.updataSubmit}},[_vm._v(" "+_vm._s(_vm.$t('course.t36'))+" "),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.lodingflag),expression:"lodingflag"}],attrs:{"size":"large"}})],1):_c('p',{staticClass:"tac cur su-btn",class:{
        disabled: (_vm.id == 0 && !_vm.submitOn) || (_vm.id != 0 && !_vm.submitOn)
      },staticStyle:{"position":"relative"},on:{"click":_vm.courseSubmit}},[_vm._v(" "+_vm._s(_vm.$t('course.t31'))+" "),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.lodingflag),expression:"lodingflag"}],attrs:{"size":"large"}})],1)]),_c('p',{staticClass:"info"},[_vm._v(_vm._s(_vm.$t('course.t32')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.id != 0),expression:"id != 0"}],staticClass:"flex ai-c jc-s cur app-reg",on:{"click":_vm.entranceTestEvent}},[_c('img',{attrs:{"src":require("@/assets/imgs/yuyue.png"),"alt":""}}),_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t('course.t33')))]),_vm._m(0)]),_c('div',{staticClass:"hot-course"},[_c('div',{staticClass:"pos-r hc-title"},[_vm._v(_vm._s(_vm.$t('course.t34')))]),_c('ul',{staticClass:"hc-body"},_vm._l((_vm.courseList),function(item,index){return _c('div',{key:index},[(index < 3)?_c('li',{staticClass:"cur",on:{"click":function($event){return _vm.signUpEventNewItem(item)}}},[_c('div',{staticClass:"hcb-title"},[_vm._v(_vm._s(item.courseName))]),_c('div',{staticClass:"hcb-body"},[_vm._v(_vm._s((_vm.personInfo?.moneySymbol || 'S$') + ' ' + item?.totalPrice))])]):_vm._e()])}),0)]),_c('s-modal',{ref:"modal",on:{"registSuc":_vm.handleOk}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"pos-r right"},[_c('i',{staticClass:"pos-a"})])
}]

export { render, staticRenderFns }