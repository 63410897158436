
    import { Component, Vue, Watch, Prop } from "vue-property-decorator"
    import { mapGetters } from 'vuex'
    import { Caxios } from './../../../../utils/axios'
    const _ = require('lodash')
    @Component({
        name: "CourseTest",
        computed: {
            ...mapGetters('common', [ 'lang' ])
        }
    })
    export default class CourseTest extends Vue {
        @Prop() id!: any
        lang!: any
        private grade: any = []
        private selectGradeItem: any = {}
        private tableResult: any = []
        private school: any = {
            list: []
        }
        private selectSchoolItem: any = {}
        private subject: any = {
            list: []
        }
        private selectSubjectList: any = []
        private resultList: any = []
        private selectTableItem: any = []
        private selectTableIndex: any = {}
        private result: any = []
        private reqObj: any = {}
        private testFlag = false
      // 暂无数据，点击申请预约测试
      private async applyTest () {
        if(this.testFlag)return
        const _this: any = this
        const res: any = await Caxios.post({ url: '/api/official/testClass/applyTest' })
        if(res.code === '10000'){
          this.testFlag = true
          _this.$confirm({
            title: "提示",
            subTitle: this.lang === 'zh-CN' ? "您的【预约测试】申请发送成功，老师会尽快联系您！您也可以主动联系我们，咨询电话：80382563!" : "Your application of [ appointment for test] has been sent successfully. The teacher will contact you as soon as possible! You can also take the initiative in contacting us. For more informa- tion, advise Tel: 80382563！",
            width: "320px",
            showClose: false,
            btns: {
              sure: this.lang === 'zh-CN' ? "知道了！" : "Got it",
              cancel: "",
            },
          })
        }
      }

        // 获取条件
        private async getTestClassOptionsInfo() {
            const route = this.$route
            const res: any = await Caxios.post({ url: '/api/official/testClass/testClassOptionsInfo',data:{
                branchSchoolCode: route.query.branchSchoolCode
                }})
            if(res.code === '10000'){
              this.grade = []
                // 年级
                Object.keys(res.data.gradeGroupSelectorVOList).forEach(i => {
                    const obj = res.data.gradeGroupSelectorVOList[i]
                    obj.gradeSelectorVOList = obj.gradeSelectorVOList.map((item: any) => {
                        return {
                            ...item,
                            disabled: false,
                            full: false,
                            on: false
                        }
                    })
                    console.log('-----', this.grade)
                    this.grade.push(obj)
                })
                this.reqObj.gradeGroupSelectorVOList = res.data.gradeGroupSelectorVOList
                // 校区
                this.$set(this.school, 'list', res.data.schoolAreaSelectorVOList.map((item: any) => {
                    return {
                        ...item,
                        disabled: false,
                        full: false,
                        on: false
                    }
                }))
                this.reqObj.schoolAreaSelectorVOList = res.data.schoolAreaSelectorVOList
                // 科目
                this.$set(this.subject, 'list', res.data.subjectSelectorVOList.map((item: any) => {
                    return {
                        ...item,
                        disabled: false,
                        full: false,
                        on: false
                    }
                }))
                this.reqObj.subjectSelectorVOList = res.data.subjectSelectorVOList
                // 获取表格
                await this.generateTestClassTable()
            }
        }
        // 获取表格
        private async generateTestClassTable() {
            const route = this.$route
            const res: any = await Caxios.post({ url: '/api/official/testClass/generateTestClassTable',data:{
                    ...this.reqObj,
                    subjectCode: this.selectSubjectList.map((cell: { subjectCode: any }) => cell.subjectCode),
                    gradeCode: this.selectGradeItem.gradeCode ? this.selectGradeItem.gradeCode : '',
                    schoolAreaId: this.selectSchoolItem.schoolAreaId ? this.selectSchoolItem.schoolAreaId : '',
                    classIdList: this.selectTableItem.map((cell: { classId: any }) => cell.classId),
                    branchSchoolCode: route.query.branchSchoolCode
                } })
            if(res.code === '10000'){
                this.tableResult = CourseTest.setTableData(res)
                // 设置默认选中
                console.log('this.result', this.tableResult)
                console.log('this.selectTableItem', this.selectTableItem)
                if (Object.keys(this.selectTableIndex).length > 0) {
                    let cacheSelect: any = []
                    Object.keys(this.tableResult).forEach(i => {
                        Object.keys(this.tableResult[i].bodyList).forEach(a => {
                            Object.keys(this.tableResult[i].bodyList[a].bodyDataList).forEach(b => {
                                const hasList = []
                                Object.keys(this.tableResult[i].bodyList[a].bodyDataList[b].classInfoList).forEach(c => {
                                    const find = this.selectTableItem.find((item: { classId: any; timeRange: any }) => item.classId === this.tableResult[i].bodyList[a].bodyDataList[b].classInfoList[c].classId
                                        && item.timeRange === this.tableResult[i].bodyList[a].bodyDataList[b].classInfoList[c].timeRange
                                    )
                                    if (find) {
                                        cacheSelect = this.tableResult[i].bodyList[a].bodyDataList[b].classInfoList
                                        hasList.push(find)
                                    }
                                })
                                this.$set(this.tableResult[i].bodyList[a].bodyDataList[b], 'on', hasList.length > 0)
                            })
                        })
                    })
                    this.selectTableItem = cacheSelect
                }
                // 设置年级
                const gradeCodeList: any = []
                Object.keys(res.data.gradeGroupSelectorVOList).forEach(b => {
                    Object.keys(res.data.gradeGroupSelectorVOList[b].gradeSelectorVOList).forEach(c => {
                        gradeCodeList.push(res.data.gradeGroupSelectorVOList[b].gradeSelectorVOList[c])
                    })
                })
                Object.keys(this.grade).forEach(i => {
                    Object.keys(this.grade[i].gradeSelectorVOList).forEach(a => {
                        const find = gradeCodeList.find((item: { gradeCode: any }) => item.gradeCode === this.grade[i].gradeSelectorVOList[a].gradeCode)

                        if (this.grade[i].gradeSelectorVOList[a].disable) {
                            this.$set(this.grade[i].gradeSelectorVOList[a], 'on', false)
                            // 如果比对不一致 自动再请求一次
                            if (this.grade[i].gradeSelectorVOList[a].disable !== find.disable) {
                                this.generateTestClassTable()
                                this.$set(this.grade[i].gradeSelectorVOList[a], 'on', false)
                            }
                        }
                        if (find) {
                            this.$set(this.grade[i].gradeSelectorVOList[a], 'disabled', find.disable)
                            this.$set(this.grade[i].gradeSelectorVOList[a], 'state', find.state)
                        }
                    })
                })
                // 设置校区
                Object.keys(this.school.list).forEach(i => {
                    const find = res.data.schoolAreaSelectorVOList.find((item: { schoolAreaId: any }) => item.schoolAreaId === this.school.list[i].schoolAreaId)
                    if (find) {
                        if (find.disable) {
                            this.$set(this.school.list[i], 'on', false)
                        }
                        // 如果比对不一致 自动再请求一次
                        if (this.school.list[i].disabled !== find.disable) {
                            this.$set(this.school.list[i], 'on', false)
                            this.generateTestClassTable()
                        }
                        this.$set(this.school.list[i], 'disabled', find.disable)
                        this.$set(this.school.list[i], 'state', find.state)
                    }
                })
                // 设置科目
                Object.keys(this.subject.list).forEach(i => {
                    const find = res.data.subjectSelectorVOList.find((item: { subjectCode: any }) => item.subjectCode === this.subject.list[i].subjectCode)
                    if (find) {
                        if (find.disable) {
                            this.$set(this.subject.list[i], 'on', false)
                        }
                        // 如果比对不一致 自动再请求一次
                        if (this.subject.list[i].disabled !== find.disable) {
                            const findSubject = this.selectSubjectList.findIndex((cell: { subjectCode: any }) => cell.subjectCode === this.subject.list[i].subjectCode)
                            if (findSubject !== -1) {
                                this.$delete(this.selectSubjectList, findSubject)
                            }
                            this.$set(this.subject.list[i], 'on', false)
                            this.generateTestClassTable()
                        }
                        this.$set(this.subject.list[i], 'disabled', find.disable)
                        this.$set(this.subject.list[i], 'state', find.state)
                    }
                })
            }
        }
        // 转换表格数据
        private static setTableData (res: any) {
            const tableBodyData = res.data.testClassTableResponseVO.bodyList
            Object.keys(tableBodyData).forEach((i: any) => {
                tableBodyData[i].bodyDataList = tableBodyData[i].bodyDataList.map((item: any) => {
                    return {
                        ...item,
                        on: false
                    }
                })
            })
            let head = res.data.testClassTableResponseVO.headList
            const body = tableBodyData
            let tableList: any = []
            console.log('head.length', head.length)
            if (head.length > 7) {
                head = _.chunk(head, 7)
                Object.keys(head).forEach(i => {
                    tableList.push({})
                    tableList[i].headList = head[i]
                    tableList[i].bodyList = []
                    Object.keys(tableBodyData).forEach(a => {
                        const obj: any = {}
                        obj.bodyTime = tableBodyData[a].bodyTime
                        obj.bodyDataList = _.chunk(tableBodyData[a].bodyDataList, 7)[i]
                        tableList[i].bodyList.push(obj)
                    })
                })
            } else {
                tableList = [{
                    headList: head,
                    bodyList: body
                }]
            }
            return tableList
        }

        // 选择年级
        private async selectGrade(flag: any, value: { on: any; value: any; index: string | number; subIndex: string | number }, name: any) {
            if (flag && !value.on) return false
            Object.keys(this.grade).forEach(i => {
                Object.keys(this.grade[i].gradeSelectorVOList).forEach(a => {
                    this.$set(this.grade[i].gradeSelectorVOList[a], 'on', false)
                })
            })
            console.log('value.value', value.value)
            if (!value.value) {
                this.$set(this.grade[value.index].gradeSelectorVOList[value.subIndex], 'on', true)
                this.selectGradeItem = this.grade[value.index].gradeSelectorVOList[value.subIndex]
            } else {
                this.selectGradeItem = {}
            }
            this.getSubmitState()
            await this.generateTestClassTable()
        }
        // 选择校区
        private async selectSchool(flag: any, value: { on: any; value: any; index: string | number }, name: any) {
            if (flag && !value.on) return false
            Object.keys(this.school.list).forEach(i => {
                this.$set(this.school.list[i], 'on', false)
            })
            if (!value.value) {
                this.$set(this.school.list[value.index], 'on', true)
                this.selectSchoolItem = this.school.list[value.index]
            } else {
                this.selectSchoolItem = {}
            }
            this.getSubmitState()
            await this.generateTestClassTable()
        }
        // 选择老师
        private async selectSubject(flag: any, value: { on: any; index: string | number; value: any; item: { subjectCode: any } }, name: any) {
            if (flag && !value.on) return false
            this.$set(this.subject.list[value.index], 'on', !value.value)
            const find = this.selectSubjectList.findIndex((item: { subjectCode: any }) => item.subjectCode === value.item.subjectCode)
            if (find !== -1) {
                this.$delete(this.selectSubjectList, find)
            } else {
                this.selectSubjectList.push(value.item)
            }
            this.getSubmitState()
            await this.generateTestClassTable()
        }
        // 选表格
        private async selectTable (flag: string, value: { value: any; cIndex: string | number; index: string | number; subIndex: string | number }) {
            if (flag === 'empty') return false
            if (!value.value) {
                this.selectTableItem = this.tableResult[value.cIndex].bodyList[value.index].bodyDataList[value.subIndex].classInfoList
                this.selectTableIndex = value
            } else {
                this.selectTableItem = []
                this.selectTableIndex = {}
            }
            this.getSubmitState()
            await this.generateTestClassTable()
        }

        // 查询提交按钮状态
      private async  getSubmitState () {
            let flag = false
            if (
                Object.keys(this.selectGradeItem).length > 0
                && Object.keys(this.selectSchoolItem).length > 0
                && this.selectSubjectList.length > 0
                && this.selectTableItem.length > 0
            ) {
                flag = true
            }
            (this as any).$eventBus.$emit('courseTestDetail',
                {submitOn:flag,
                 selectTableItem:this.selectTableItem,
                 testInfo:{
                   selectGradeItem:this.selectGradeItem,
                   selectSchoolItem:this.selectSchoolItem,
                   selectSubjectList:this.selectSubjectList,
                   selectTableItem:this.selectTableItem
                 },
                  orderGoodsId: this.$route.query?.orderGoodsId
                })
            // this.submitOn = flag
        }
      private async deleteSelectObj (type: number) {
        console.log('number',type)
        switch(type) {
          case 0:
            this.$set(this,'selectGradeItem',{})
            break
          case 1:
            this.$set(this,'selectSchoolItem',{})
            break
          case 2:
            this.$set(this,'selectSubjectList',[])
            break
          case 3:
            this.$set(this,'selectTableItem',[])
            break
          case 4:
            this.$set(this,'selectGradeItem',{})
            this.$set(this,'selectSchoolItem',{})
            this.$set(this,'selectSubjectList',[])
            this.$set(this,'selectTableItem',[])
            break
        }
        this.getTestClassOptionsInfo()
      }
        beforeDestroy() {
            (this as any).$eventBus.$off('courseIsSelect');
        }
        created(): void {
            (this as any).$eventBus.$on('courseIsSelect', () => {
              const _this: any = this
              if (!this.selectGradeItem.gradeCode) {
                _this.$message.warning("请选择年级");
                // this.setUnSelectFlagStr('grade')
                return;
              }
              if (!this.selectSchoolItem.schoolAreaId) {
                _this.$message.warning("请选择校区");
                // this.setUnSelectFlagStr('area')
                return;
              }
              if (this.selectSubjectList.length == 0) {
                _this.$message.warning("请选择学科");
                // this.setUnSelectFlagStr('subject')
                return;
              }
              if (this.selectTableItem.length == 0) {
                _this.$message.warning("请选择日期");
                // this.setUnSelectFlagStr('restnum')
                return;
              }
              (this as any).$eventBus.$emit('signTestUp');
            });
          this.getTestClassOptionsInfo()
        }
    }
