
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import { Caxios } from "./../../../../utils/axios";
import SModal from "../components/modal.vue"
import qs from "qs";
@Component({
  name: "MineCurriculum",
  computed: mapGetters("course", [
    "curriculumObj",
    "hotCourseList",
    "selectObj",
    "canSignupFlag",
  ]),
  methods: {
    ...mapMutations("common", ["setCommonComponentName"]),
    ...mapMutations('course', ['setUnSelectFlagStr'])
  },
  components: {
    SModal
  }
})
export default class MineCurriculum extends Vue {
  curriculumObj!: any;
  selectObj!: any;
  hotCourseList!: Array<any>;
  canSignupFlag!: boolean;
  setCommonComponentName!: any;
  setUnSelectFlagStr!: any
  @Prop() id!: any;
  public testFlag = true
  public orderGoodsId: any = ''
  public submitOn = false
  public personInfo: any = localStorage.getItem('singapore_personal_Data') || ''
  public courseList: any = localStorage.getItem('courseList')
  private selectInfo: any = {}
  private selectTestInfo: any = {}
  private testInfo: any = {}
  private pathType: any = ''

  public lodingflag = false

  private signUpEventNewItem(item: any): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this,
      _route: any = _this.$route,
      _courseid: string = _route.query.courseid;
    if (_courseid != item.course_id) {
      _this.$router.replace(`/course/detail?courseid=${item.courseId}&branchSchoolCode=${_route.query.branchSchoolCode}`);
      window.location.reload();
    }
  }

  private handleOk(): void {
    const _this = this
    // this.$message.success("报名成功");
    sessionStorage.removeItem('fangtian_person_to_course_orderid')
    this.clearSession();
    _this.$router.push({
      path: "/personal/index",
      query: {
        openCourse: '1',
        lessonstatus: 'pay',
        price: this.selectObj.totalprice,
        tab: this.id == 0 ? (!this.selectTestInfo?.totalPrice ? '2' : '0') : (this.selectInfo?.totalPrice == 0 ? '2' : '0')
      }
    });
  }

  private entranceTestEvent(): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this;
    const router: any = this.$route
    _this.$router.replace(`/course/detail?courseid=0&branchSchoolCode=${router.query.branchSchoolCode}`);
    this.submitOn = false
  }
  private clearSession(): void {
    if (sessionStorage.getItem("fangtian_person_to_course")) {
      sessionStorage.removeItem("fangtian_person_to_course");
    }
  }
  /*  // 判断是否已有入学测成绩
    private async getTestScore(): Promise<any> {
      const query = this.$route.query
      const data: any = { stdid: '', courseid: '' }
      if (query) {
        data.courseid = query.courseid
      }
      const userInfo = localStorage.getItem('singapore_personal_Data')
      if (userInfo) {
        data.stdid = JSON.parse(userInfo).id
      }
      const res: any = await Caxios.get({url: `/api/student/score/validStudentTestScore?${qs.stringify(data)}`})
      if (res?.length <= 0) {
        this.testFlag = true
      }else {
        console.log('888')
        this.testFlag = false
      }
    }*/
  // 提交订单
  private async courseSubmit() {
    // 通知外部检查选中情况
    (this as any).$eventBus.$emit('courseIsSelect');
    if (this.id != 0 && this.submitOn) {
      this.signUp()
    } else if (this.id == 0 && this.submitOn) {
      if (this.orderGoodsId) {
        this.updateTestOrder()
      } else {
        this.signTestUp()
      }
    }
  }
  /*我的课程-测试订单修改*/
  private async updateTestOrder() {
    const moment = require('moment')
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const subjectGradeRelations = this.testInfo.selectSubjectList.map((item: any) => {
      return {
        subjectCode: item.subjectCode,
        gradeInfoList: [{ gradeCode: this.testInfo.selectGradeItem.gradeCode }]
      }
    })
    const res: any = await Caxios.post({
      url: '/api/official/order/updateTestOrder',
      data: {
        gradeList: [this.testInfo.selectGradeItem.gradeCode],
        // subjectList:[this.testInfo.selectSubjectList[0].subjectCode],
        subjectList: this.testInfo.selectSubjectList.map((i: any) => i.subjectCode),
        targetClassId: this.selectTestInfo.classId,
        orderGoodsId: this.orderGoodsId
      }
    })
    if (res.code === '10000') {
      this.$message.success('修改成功')
      this.$router.push({ path: "/personal/index", query: { openCourse: '1', lessonstatus: 'pay', price: this.selectObj.totalprice, tab: '2' } });

    }
  }
  /*测试班-下单*/
  private async signTestUp() {
    const moment = require('moment')
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const subjectGradeRelations = this.testInfo.selectSubjectList.map((item: any) => {
      return {
        subjectCode: item.subjectCode,
        gradeInfoList: [{ gradeCode: this.testInfo.selectGradeItem.gradeCode }]
      }
    })
    const res: any = await Caxios.post({
      url: '/api/official/order/placeTestOrder',
      data: {
        busiTime: currentTime,
        orderItemList: [{
          classId: this.selectTestInfo.classId,
          subjectGradeRelations,
          amount: 0
        }],
        amount: 0
      }
    })
    if (res.code === '10000') {
      this.createTrade(res.data.id)

    }
  }
  /*订单-下单校验课程下是否已有订单*/
  private async signUp() {
    const res: any = await Caxios.post({ url: '/api/official/order/checkRepeat', data: { classIdList: [this.selectInfo.classId] } })
    if (res.code === '10000') {
      if (res.data.repeat) {
        const _this: any = this;
        _this.$confirm({
          title: this.$t('center.t99'),
          subTitle: res.data.title,
          width: "400px",
          showClose: false,
          btns: {
            sure: "关闭",
          },
        })
      } else {
        this.calculateAmount()
      }
    }
  }
  /*订单-金额计算接口*/
  private async calculateAmount() {
    const res: any = await Caxios.post({ url: '/api/official/order/calculateAmount', data: { orderItemList: [{ classId: this.selectInfo.classId, couponId: '' }] } })
    if (res.code === '10000') {
      this.placeOrder(res.data)
    }
  }
  /*订单-下单接口 */
  private async placeOrder(record: any) {
    const _this: any = this
    const moment = require('moment')
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const res: any = await Caxios.post({ url: '/api/official/order/placeOrder', data: { busiTime: currentTime, orderItemList: record.dtoList, classId: this.selectInfo.classId, amount: record.totalAmount } })
    if (res.code === '10000') {
      if (record.totalAmount == 0) {
        this.createTrade(res.data.id)
        return
      }
      this.paySuccess()
    }
  }
  private paySuccess() {
    const _this: any = this
    if ((this.id != 0 && this.selectInfo.totalPrice === 0) || this.id == 0) {
      this.handleOk()
    } else {
      _this.$refs.modal.open()
    }
  }
  /*创建支付订单*/
  private async createTrade(id: any) {
    console.log('id', id)
    const _this: any = this
    const res: any = await Caxios.post({ url: '/api/official/order/createTrade', data: { mergeOrderId: id } })
    if (res.code === '10000') {
      if (id == 0) {
        this.$message.success('恭喜你，报名成功！')
        this.$router.push({
          path: "/personal/index",
          query: {
            openCourse: '1',
            lessonstatus: 'pay',
            price: this.selectObj.totalprice,
            tab: this.id == 0 ? (this.selectTestInfo?.totalPrice == 0 ? '2' : '0') : (this.selectInfo?.totalPrice == 0 ? '2' : '0')
          }
        });
      } else {
        _this.paySuccess()
      }
    }
  }
  /*活动课修改订单*/
  private async updataSubmit () {
    console.log('修改订单')
    const _this: any = this
    const route: any = this.$route
    const res: any = await Caxios.post({ url: '/api/official/classLessonChange/changeClass', data: {
        destClassId: this.selectInfo.classId,
        oriClassId: route.query.classId,
        signUrl: '',
      } })
    if (res.code === '10000') {
      this.$message.success(res.msg || '修改成功')
      this.$router.push({
        path: "/personal/index",
        query: {
          tab: '2'
        }
      });
    }
  }
  beforeMount() {
    (this as any).$eventBus.$on('courseDetail', (data: any) => {
      console.log('$on1', data);
      // 设置下单信息
      this.$set(this.selectInfo, 'courseId', data.courseId);
      this.$set(this.selectInfo, 'courseName', data.courseName);
      this.$set(this.selectInfo, 'lessonCount', data.lessonCount);
      this.$set(this.selectInfo, 'courseTime', data.courseTime);
      this.$set(this.selectInfo, 'totalPrice', 0);
      this.$set(this.selectInfo, 'className', null);
      this.$set(this.selectInfo, 'schoolAreaName', null);
      this.submitOn = false
    });
    (this as any).$eventBus.$on('courseSelect', (data: any) => {
      console.log('$on2', data);
      this.$set(this.selectInfo, 'classFrequency', data.classFrequency);
      this.$set(this.selectInfo, 'classId', data.classId);
      this.$set(this.selectInfo, 'className', data.className);
      this.$set(this.selectInfo, 'totalPrice', data.totalPrice);
      this.$set(this.selectInfo, 'schoolAreaName', data.schoolAreaName);
      this.submitOn = true
    });
    (this as any).$eventBus.$on('courseTestDetail', (data: any) => {
      console.log('$on3', data);
      this.$set(this, 'selectTestInfo', data.selectTableItem[0])
      this.$set(this, 'testInfo', data.testInfo)
      this.orderGoodsId = data.orderGoodsId
      this.submitOn = data.submitOn
    });
    /*(this as any).$eventBus.$on('signUp', (data: any) => {
      this.signUp()
    });*/
    /*(this as any).$eventBus.$on('signTestUp', (data: any) => {
      this.signTestUp()
    });*/
    if (this.personInfo) {
      this.personInfo = JSON.parse(this.personInfo)
    }
  }
  mounted() {
    const route: any = this.$route
    console.log('route', route)
    this.pathType = route.query.pathType
    // this.getTestScore()
    this.courseList = JSON.parse(this.courseList)
  }
  beforeDestroy() {
    (this as any).$eventBus.$off('courseSelect');
    (this as any).$eventBus.$off('courseDetail');
    (this as any).$eventBus.$off('courseTestDetail');
    this.clearSession();
  }
}
