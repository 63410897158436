
import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";


@Component({
  name: "CommonSelect",
  computed: {
    ...mapGetters('common', ['lang']),
  },
})
export default class CommonSelect extends Vue {
  lang!: any;
  @Prop() classInfo!: any;
  @Prop() schoolInfo!: any;
  @Prop() teacherInfo!: any;
  @Prop() result!: any;
  @Prop() selectClassItem!: any;
  @Prop() selectSchoolItem!: any;
  @Prop() selectTeacherItem!: any;
  @Prop() selectTableItem!: any;
  @Prop() activityLevel!: any;
  private classIsSelect: any = false
  private schoolIsSelect: any = false
  private teacherIsSelect: any = false
  private tableIsSelect: any = false

  // 选择班级
  private async selectClass(flag: any, value: { value: any; index: string | number }, name: any) {
    this.$emit('selectClass', flag, value);
    this.classIsSelect = false
    console.log('选中',flag, value);
  }
  // 选择校区
  private async selectSchool(flag: any, value: { value: any; index: string | number }, name: any) {
    this.$emit('selectSchool', flag, value);
    this.schoolIsSelect = false
  }
  // 选择老师
  private async selectTeacher(flag: any, value: { value: any; index: string | number }, name: any) {
    this.$emit('selectTeacher', flag, value);
    this.teacherIsSelect = false
  }
  // 选择表格
  private async selectTable(flag: any, value: { value: any; index: string | number }, name: any) {
    this.$emit('selectTable', flag, value);
    this.tableIsSelect = false
  }
  created(): void {
    (this as any).$eventBus.$on('courseIsSelect', () => {
      this.classIsSelect = Object.keys(this.selectClassItem).length <= 0;
      if (this.classIsSelect) return
      this.schoolIsSelect = Object.keys(this.selectSchoolItem).length <= 0;
      if (this.schoolIsSelect) return
      this.teacherIsSelect = Object.keys(this.selectTeacherItem).length <= 0;
      if (this.teacherIsSelect) return
      this.tableIsSelect = this.selectTableItem.length <= 0;
      (this as any).$eventBus.$emit('signUp');
    });
  }

  beforeDestroy() {
    (this as any).$eventBus.$off('courseIsSelect');
  }
}
